body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'), url(./fonts/WorkSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DMMono';
  src: local('DMMono'), url(./fonts/DMMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans'), url(./fonts/DMSans-VariableFont_opsz\,wght.ttf) format('truetype');
}

@font-face {
  font-family: 'PlayFair';
  src: local('PlayFair'), url(./fonts/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Abril';
  src: local('Abril'), url(./fonts/AbrilFatface-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url(./fonts/Jost-Regular.ttf) format('truetype');
  -webkit-font-feature-settings: "kern" 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
}

@font-face {
  font-family: 'Modern';
  src: local('Modern'), url(./fonts/ModernSans-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Mirage';
  src: local('Mirage'), url(./fonts/Mirage.otf) format('opentype');
}

@font-face {
  font-family: 'Mirage Bold';
  src: local('Mirage Bold'), url(./fonts/MirageBold.otf) format('opentype');
}

@font-face {
  font-family: 'Mirage Black';
  src: local('Mirage Black'), url(./fonts/MirageBlack.otf) format('opentype');
}

@font-face {
  font-family: 'Bebas';
  src: local('Bebas'), url(./fonts/BebasNeue-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Display';
  src: local('Display'), url(./fonts/DMSerifDisplay-Regular.ttf) format('truetype');
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'ZTbros';
  src: local('ZT Bros Oskon 90s'), url(./fonts/ZTBrosOskon90s-Regular.otf) format('opentype');
}
